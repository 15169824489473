import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { PublicationService } from '../publications/publication.service';
import { PieceJointe, Publication } from '../publications/publication.model';
import { FeaturesService } from '../../../features.service';
import { Router } from '@angular/router';
import { Procedure } from '../document-administratif/demande-administratif.models';
import { PROCEDURE_ACCT, PROCEDURE_BCMS, PROCEDURE_DA, PROCEDURE_DAMOF, PROCEDURE_DELF, PROCEDURE_DIT, PROCEDURE_ITT, PROCEDURE_RG, PROCEDURE_SRH } from '../document-administratif/demande-administratif.procedure';
import { DemandeAdministratifService } from '../document-administratif/demande-administratif.service';
import { EnqueteService } from '../contributions/enquete/enquete.service';
import { Enquete } from '../../models/enquete';
import { SearchInfos } from '../espace-utilisateur/lanaya/lanaya.model';
import { LanayaService } from '../espace-utilisateur/lanaya/lanaya.service';

@Component({
    selector: 'app-home-three',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
    islogging: Boolean = false;
    publications: Array<Publication> = [];
    procedures: Procedure[] = [
        ...PROCEDURE_ACCT,
        ...PROCEDURE_DA,
        ...PROCEDURE_SRH,
        ...PROCEDURE_DAMOF,
        ...PROCEDURE_BCMS,
        ...PROCEDURE_DELF,
         ...PROCEDURE_DIT,
         ...PROCEDURE_ITT,
          ...PROCEDURE_RG,];
    proceduresFrequently!: Procedure[];
    display: boolean = false;
    searchInfo: SearchInfos = {numeroIfu: '', numeroEsintax: ''};
    esintaxErrorMessage: string;
    demandes: any[] = [];
    limite = 6;
    enquetes: Enquete[];
    toutesLesProcedures=[]
    constructor(private ks: KeycloakService,
                private featuresService: FeaturesService,
                private lanayaService: LanayaService,
                private router: Router,

                private demandeService: DemandeAdministratifService,
                private publicationService: PublicationService, private enqueteService: EnqueteService) {
    }


    ngOnInit(): void {
        this.demandeService.getProcedures().subscribe({
            next:(response)=>{
                this.toutesLesProcedures=response.body


            },
            error:(errors)=>{
                console.log("une erreur est survenue lors de la recuperation des procedures les plus utilisées")
            },
            complete:console.log
        })
        if (this.ks.isLoggedIn()) {
            this.islogging = true;
        }

        this.loadPublications();
        this.loadMostFrequently();
        this.loadEnquete();
    }

    participer(enquete: Enquete) {
        this.router.navigate(['contribution/enquete', enquete.id, 'participation']);
    }

    //TODO review for optimization purpose
    loadMostFrequently() {
        this.demandeService.getMostFrequentlyUsed(this.limite).subscribe(data => {
            this.demandes = data.body;
            this.proceduresFrequently = data.body;



        });


        //lorsque le nombre de procedure n atteint pas la limite on comble le vide avec des procedures ou loadAsFrequently est true
          if (this.proceduresFrequently?.length < this.limite) {
            let i = 0;
            while (this.proceduresFrequently.length < this.limite && i < this.toutesLesProcedures.length) {
                if (this.toutesLesProcedures[i].loadAsFrequently) {
                    const exists = this.proceduresFrequently.some(p => p.type === this.toutesLesProcedures[i].type);
                    if (!exists) {
                        this.proceduresFrequently.push(this.toutesLesProcedures[i]);
                    }
                }
                i++;
            }
        }




    }

    loadEnquete() {

        this.enqueteService.getEnquetesNotConnect().subscribe({
            next: (response) => {
                this.enquetes = response.body;

            },
            error: (error) => {
            }
        });

    }


    loadPublications() {
        this.publicationService.getByAllVisibleWithLimit(5)
            .subscribe({
                next: resp => this.publications = resp.body || []
            });
    }

    allDowload(pub: Publication) {
        pub.pjList.forEach(value => {
            this.downloadFile(value);
        });
    }

    downloadFile(pj: PieceJointe) {
        this.publicationService.downoadFile(pj);
    }

    checkContribuable() {
        this.lanayaService.checkContribuableExists(this.searchInfo)
            .subscribe({
                next: res => {
                    this.featuresService.onSearchInfosChange(this.searchInfo);
                    this.display = false;
                    this.router.navigate(['/espace-utilisateur/paiements']);
                },
                error: () => {
                    this.esintaxErrorMessage = 'Les informations saisies ne sont pas correctes.';

                    setTimeout(() => {
                        this.esintaxErrorMessage = undefined;
                    }, 5000);
                }
            });
    }

    get isValid(): boolean {
        return !!(this.searchInfo.numeroEsintax && this.searchInfo.numeroEsintax.trim().length > 0 && this.searchInfo.numeroIfu && this.searchInfo.numeroIfu.trim().length > 0);
    }

    onDisplay() {
        if (this.display) {
            this.display = false;
            this.searchInfo = {numeroIfu: '', numeroEsintax: ''};
        } else {
            if (this.ks.isLoggedIn()) {
                this.display = true;
            } else {
                this.ks.login();
            }
        }
    }

    allProcedure() {
        this.router.navigate(['/document-administratif/acct']);
    }

    soumettre(procedure: Procedure) {
        this.router.navigate(['/detail', procedure.type]);
    }

}
