<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 ">
                <div class="footer-widget align-items-center">

                    <img src="assets/img/emblem.png" width="28%" style="    margin-left: 30%; margin-top: 11%;;"
                         alt="Images">

                    <ul class="footer-list-two">
                        <li style="text-align: center">
                            Ministère de l’Économie et des Finances
                        </li>

                    </ul>

                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <div class="footer-widget">
                        <h3>Contacts</h3>
                    </div>
                    <ul class="footer-list-two">
                        <li>
                            Adresse:
                            Koulouba,
                            806 avenue de l’Indépendance
                            01 BP 92 Ouagadougou 01
                            Burkina Faso
                        </li>
                        <li>
                            <a href="tel:+61283171380">Téléphone : (00226) 25 32 49 51 / 25 32 49 41</a>
                        </li>

                        <li>

                            <a href="mailto:info&#64;gloz.com">Email : tresorpublic&#64;gov.bf</a>
                        </li>

                    </ul>


                </div>
            </div>


            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Procédures les plus utilisées</h3>
                    <ul class="footer-list-two">
                        <li *ngFor="let procedure of proceduresFrequentlyFooter | slice:0:3">
                            <a [routerLink]="procedure?.requestUrl">{{ procedure?.title }}</a>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Liens Utiles</h3>

                    <ul class="footer-list">

                        <li><a routerLink="/document-administratif/acct">Procédures dématérialisées</a></li>
                        <li><a routerLink="/contribution/enquete">Contributions</a></li>
                        <li><a routerLink="/lanaya/demandes">Suivi des dossiers des dépenses publics</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="/faq">FAQ</a></li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
    <div class="container mt-4 row copyright">
        <div class="col-11 text-center">
            &copy; Copyright <strong><span>2024</span></strong>. Tous droits réservés.
            <a
                    href="https://servicepublic.gov.bf/contact/direction-generale-du-tresor-et-de-la-comptabilite-publiques">
                Direction Générale du Trésor et de la Comptabilité Publiques (DGTCP)
            </a>
        </div>
        <div class="col-1 text-right">
            <span class="font-bold">v{{appVersion}}</span>
        </div>
    </div>
</footer>
